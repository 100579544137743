import styled from 'styled-components';

const HiringWrapper = styled.section`
  padding: 20px 0 0px;
  margin-bottom: 50px;
  @media (max-width: 1440px) {
    padding: 0px 0 0px;
  }
  @media (max-width: 480px) {
    padding: 80px 0 0px;
  }
  button {
    border-radius: 6px;
    background-image: -moz-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -webkit-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -ms-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    &:hover {
      box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
    }
    > span {
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
  .logos {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .image {
    height: 100%;
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  }
`;

export { HiringWrapper };
