import styled from 'styled-components';

const ScalableWrapper = styled.section`
  padding: 50px 0 0px;
  min-height: 100vh;
  .colleft {
    width: calc(50% - 70px);
    padding-bottom: 40px;
    @media (max-width: 1199px) {
      width: 100%;
    }
    .TrustedImageBtn {
      display: flex;
      img {
        width: 150px;
        height: 47px;
      }
      .app_image_area {
        margin-right: 20px;
      }
    }
  }
  .colright {
    display: flex;
    justify-content: center;
    @media (max-width: 1199px) {
      width: 100%;
      margin-top: 100px;
    }
    @media (max-width: 480px) {
      width: 100%;
      margin-top: 20px;
    }
    img {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  button {
    border-radius: 6px;
    background-image: -moz-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -webkit-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -ms-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    &:hover {
      box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
    }
    > span {
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
`;

const FeatureSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .featureWrapper {
    max-width: 100%;
    margin-bottom: 20px;
    display: flex;
    @media (max-width: 599px) {
      max-width: 100%;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 20px;
      border-width: 2px;
      border-color: rgb(233, 227, 254);
      border-radius: 10px;
      background-color: rgb(243, 242, 250);
      padding: 8px 10px;
    }
  }
`;
export { ScalableWrapper, FeatureSection };
