import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import Icon from "react-icons-kit";
import { ic_open_in_new } from "react-icons-kit/md/ic_open_in_new";
import Fade from 'react-reveal/Fade';

import { Box, Button, Container, FeatureBlock, Heading, Text } from '@components/index';
import { FeatureSection, ScalableWrapper } from './candidate.style';
import { DeviceContext } from '../../../contexts/DeviceProvider';

const CandidateSection = ({
  row,
  col,
  titleStyle,
  sectionSubTitle,
  cardArea,
  featureTitleStyle,
  featureDescriptionStyle,
  btnStyle,
  sectionHeader,
  sectionHeaderSubTitle,
  sectionTitle
}) => {
  const isSmallScreen = React.useContext(DeviceContext);
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        CANDIDATES_FEATURE {
          title
          des
        }
      }
    }
  `);

  return (
    <ScalableWrapper id="candidate_section">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionHeaderSubTitle} content="FOR CANDIDATES" />
          <Heading {...sectionTitle} content="What can we offer you" />
        </Box>
        {isSmallScreen && (
          <Box className="colright" {...col} {...cardArea}>
            <Fade up>
              <StaticImage
                src="../../../assets/image/candidate.png"
                alt="Candidate"
                layout="fixed"
                width={200}
              />
            </Fade>
          </Box>
        )}
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            <Text content="Easy, Fast, Reliant" {...sectionSubTitle} />
            <FeatureBlock
              title={
                <Heading
                  content="Getting your next job has never been easier"
                  {...titleStyle}
                />
              }
              description={
                <FeatureSection>
                  {Data.dataJson.CANDIDATES_FEATURE.map((item, index) => (
                    <div key={`feature-${index}`} className="featureWrapper">
                      <Fade up>
                        <Box>
                          <Heading
                            as="h3"
                            content={item.title}
                            {...featureTitleStyle}
                          />

                          <Text content={item.des} {...featureDescriptionStyle} />
                        </Box>
                      </Fade>
                    </div>
                  ))}
                </FeatureSection>
              }
              button={<a href='https://jobs.talentswipe.careers/'><Button icon={<Icon icon={ic_open_in_new} size={20} />} title="OPENING JOBS" {...btnStyle} /></a>}
            />
          </Box>
          {!isSmallScreen && (
            <Box className="colright" {...col} {...cardArea}>
              <Fade left>
                <StaticImage
                  src="../../../assets/image/candidate.png"
                  alt="Candidate"
                  layout="fixed"
                />
              </Fade>
            </Box>
          )}
        </Box>
      </Container>
    </ScalableWrapper>
  );
};

CandidateSection.propTypes = {
  titleStyle: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
  cardArea: PropTypes.object,
  btnStyle: PropTypes.object,
  sectionHeaderSubTitle: PropTypes.object
};

// Scalable default style
CandidateSection.defaultProps = {
  // Scalable section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Scalable section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Scalable section title default style
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
  },
  sectionHeader: {
    mb: "60px"
  },
  sectionHeaderSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#5268db",
    mb: "10px"
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0"
  },
  cardArea: {
    alignItems: 'center',
    marginBottom: '10'
  },
  sectionSubTitle: {
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['1', '1', '1', '1', '1'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
  },
  // Scalable section description default style
  featureDescriptionStyle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '27px',
  },
};

export default CandidateSection;