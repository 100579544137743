import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image"

import { Container, Text } from "@components/index";
import { HiringWrapper } from "./companies.style";
import { DeviceContext } from "../../../contexts/DeviceProvider";

const CompaniesSection = ({ sectionTitle }) => {
  const isSmallScreen = React.useContext(DeviceContext);
  const companies = useStaticQuery(graphql`
  {
    allAirtable(
      filter: {table: {eq: "Companies 🏢"}, data: {brand_consent: {eq: true}, name: {ne: "Talent Swipe"}}}
    ) {
      nodes {
        data {
            name
            logo_horizontal {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 100)
                }
              }
            }
            website
        }
      }
    }
  }
`);

if (isSmallScreen) {
  return null;
}

  return (
    <HiringWrapper id="companies_section">
      <Container>
        <Fade up>
          <div className="logos">
          <Text {...sectionTitle} content="Worked with" />
            {companies.allAirtable.nodes.map((item, index) =>(
                  <a href={item.data.website} key={index} target="_blank" rel="noopener noreferrer">
                    <GatsbyImage key={index} className="image" image={item.data.logo_horizontal.localFiles[0].childImageSharp.gatsbyImageData} alt={item.data.name} />
                  </a>
                ))}
          </div>
        </Fade>
      </Container>
    </HiringWrapper>
  );
};

CompaniesSection.propTypes = {
  sectionTitle: PropTypes.object,
};

// Transactions default style
CompaniesSection.defaultProps = {
  sectionTitle: {
    textAlign: "center",
    fontSize: ["16px", "16px"],
    fontWeight: "500",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "0"
  },
};

export default CompaniesSection;
