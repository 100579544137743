import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import Icon from "react-icons-kit";
import { ic_event_available } from "react-icons-kit/md/ic_event_available";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { Box, Button, Container, Heading, Text } from "@components/index";
import { Hidden, TimelineContent, TimelineDot, TimelineIndex, TimelineItem, TimelineWrapper } from "./process.style";
import { DeviceContext } from "../../../contexts/DeviceProvider";
import { CompaniesSection } from "..";

const RecruitmentProcessSection = ({
  sectionWrapper,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  row,
  col,
  button,
  textArea,
  imageArea,
  buttonWrapper,
  indexStyle,
  timelineTitle,
  timelineDescription
}) => {
  const isSmallScreen = React.useContext(DeviceContext);
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        RECRUITMENT_PROCESS_TIMELINE {
          title
          description
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} id="process_section">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} content="HOW WE WORK" />
          <Heading {...sectionTitle} content="Our recruitment process" />
        </Box>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            {!isSmallScreen && (
              <>
                <Fade bottom>
                  <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
                    <StaticImage height={300} src="../../../assets/image/first_meeting.png" alt="First meeting" />
                  </div>
                </Fade>
                <Fade bottom>
                  <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
                    <StaticImage height={300} src="../../../assets/image/table_meeting.png" alt="People at the table" />
                  </div>
                </Fade>
              </>
            )}
            <Fade bottom>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
                <StaticImage height={300} src="../../../assets/image/interview_success.png" alt="Success interview" />
              </div>
            </Fade>
          </Box>
          <Box {...col} {...textArea}>
            <TimelineWrapper>
              {Data.dataJson.RECRUITMENT_PROCESS_TIMELINE.map((item, index) => (
                <TimelineItem key={`timeline-item-${index}`}>
                  <TimelineIndex>
                    <Hidden>
                      <Slide bottom>
                        <Text as="span" content={item.index || `0${index + 1}`} {...indexStyle} />
                      </Slide>
                    </Hidden>
                  </TimelineIndex>
                  <TimelineContent>
                    <Hidden>
                      <Slide bottom delay={100}>
                        <Heading as="h2" content={item.title} {...timelineTitle} />
                      </Slide>
                    </Hidden>
                    <Hidden>
                      <Slide bottom delay={200}>
                        <Text content={item.description} {...timelineDescription} />
                      </Slide>
                    </Hidden>
                  </TimelineContent>
                  <TimelineDot />
                </TimelineItem>
              ))}
            </TimelineWrapper>
          </Box>
        </Box>
        <CompaniesSection />
        <Box {...buttonWrapper}>
          <AnchorLink href="#contact_section">
            <Button icon={<Icon icon={ic_event_available} size={20} />} {...button} title="HIRE FOR PROJECT" />
          </AnchorLink>
        </Box>
      </Container>
    </Box>
  );
};

RecruitmentProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageTwo: PropTypes.object,
  buttonWrapper: PropTypes.object,
  indexStyle: PropTypes.object,
  timelineTitle: PropTypes.object,
  timelineDescription: PropTypes.object
};

RecruitmentProcessSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["60px", "80px", "80px", "40px"],
    pb: ["60px", "80px", "80px", "100px"]
  }, // section header default style
  sectionHeader: {
    mb: "56px"
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#5268db",
    mb: "10px"
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0"
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center"
  },
  col: {
    pr: "15px",
    pl: "15px"
  },
  textArea: {
    width: ["100%", "100%", "55%", "50%", "55%"],
    mb: ["40px", "40px", "0", "0", "0"]
  },
  imageArea: {
    width: ["100%", "100%", "45%", "50%", "45%"],
    mb: ["30px", "40px", "40px", "0", "0"]
  },
  title: {
    fontSize: ["30px", "38px", "38px", "48px", "48px"],
    fontWeight: "300",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "15px"
  },
  description: {
    fontSize: "16px",
    color: "textColor",
    lineHeight: "1.75",
    mb: "33px"
  },
  buttonWrapper: {
    mt: ["15px", "15px"],
    flexBox: true,
    justifyContent: "center"
  },
  button: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    height: "46px"
  },
  imageOne: {
    mb: "40px",
    ml: "auto",
    mr: "auto"
  },
  imageTwo: {
    ml: "auto",
    mr: "auto",
    mb: "15%"
  },
  indexStyle: {
    fontSize: ["36px", "42px", "52px", "56px", "72px"],
    fontWeight: "300",
    color: "#eaebec",
    display: "block",
    lineHeight: "1",
    mb: "0"
  },
  timelineTitle: {
    fontSize: ["16px", "17px", "18px", "18px", "20px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "13px"
  },
  timelineDescription: {
    fontSize: ["14px", "15px", "15px", "15px", "16px"],
    lineHeight: "2",
    color: "#5d646d",
    mb: "0"
  }
};

export default RecruitmentProcessSection;
