import React from "react";
import PropTypes from "prop-types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Fade from "react-reveal/Fade";
import Icon from "react-icons-kit";
import { levelDown } from "react-icons-kit/fa/levelDown";

import { Box, Button, Container, FeatureBlock, Heading, Text } from "@components/index";
import { FeatureSection, HiringWrapper } from "./hiring.style";
import { DeviceContext } from "../../../contexts/DeviceProvider";

const HiringSection = ({ row, col, titleStyle, descriptionStyle, btnStyle, sectionSubTitle, featureTitleStyle, featureDescriptionStyle, sectionHeader, sectionHeaderSubTitleStyle, sectionTitle, imageArea, imageTwo }) => {
  const isSmallScreen = React.useContext(DeviceContext);
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        HIRING_FEATURE {
          title
          des
        }
      }
    }
  `);

  return (
    <HiringWrapper id="hiring_section">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionHeaderSubTitleStyle} content="FOR COMPANIES" />
          <Heading {...sectionTitle} content="Benefits for working with us" />
        </Box>
        {isSmallScreen && (
          <Box flexBox="true" justifyContent={"center"} {...imageArea}>
            <Fade bottom>
              <StaticImage width={200} src="../../../assets/image/first_meeting.png" alt="First meeting" />
            </Fade>
          </Box>
        )}
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: "column" }}>
            <Text content="Hiring tech people" {...sectionSubTitle} />
            <FeatureBlock
              title={<Heading content="Get qualified front-end, back-end, mobile, devops and other engineers worldwide" {...titleStyle} />}
              description={
                <Text content="We are constantly improving to deliver the best solutions. These are some of the things we are good at." {...descriptionStyle} />
              }
              button={
                isSmallScreen ? null :
                  (
                    <AnchorLink href="#process_section" offset="70">
                      <Button icon={<Icon icon={levelDown} size={20} />} title="HOW WE WORK" {...btnStyle} />
                    </AnchorLink>
                  )
              }
            />
          </Box>
          <Box className="colright" {...col}>
            <FeatureSection>
              {Data.dataJson.HIRING_FEATURE.map((item, index) => (
                <Fade up key={`feature-${index}`}>
                  <div key={`feature-${index}`} className="featureWrapper">
                    <Heading as="h3" content={item.title} {...featureTitleStyle} />
                    <Text content={item.des} {...featureDescriptionStyle} />
                  </div>
                </Fade>
              ))}
            </FeatureSection>
          </Box>
        </Box>
      </Container>
    </HiringWrapper>
  );
};

HiringSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  sectionHeaderSubTitleStyle: PropTypes.object,
  imageArea: PropTypes.object,
  imageTwo: PropTypes.object
};

// Transactions default style
HiringSection.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    justifyContent: 'center',
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px"
  },
  // Transactions section col default style
  col: {
    pr: "15px",
    pl: "15px",
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: "center"
  },
  // Transactions section title default style
  titleStyle: {
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "100%", "415px"]
  },
  // Transactions section description default style
  descriptionStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["30px", "30px", "40px", "40px", "55px"],
    maxWidth: ["100%", "100%", "100%", "100%", "430px"]
  },
  sectionHeader: {
    mb: "80px"
  },
  sectionHeaderSubTitleStyle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#5268db",
    mb: "10px"
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0"
  },
  sectionSubTitle: {
    as: "span",
    fontSize: ["16px", "16px", "18px", "20px", "20px"],
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "27px",
    color: "#525f7f"
  },
  // Button default style
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500"
  },
  featureTitleStyle: {
    fontSize: ["18px", "18px", "20px", "20px", "20px"],
    lineHeight: ["25px", "27px", "27px", "27px", "27px"],
    fontWeight: "500",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "10px"
  },
  // Transactions section description default style
  featureDescriptionStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "27px"
  },
  imageArea: {
    width: ["100%", "100%", "45%", "50%", "45%"],
    mb: ["30px", "40px", "40px", "0", "0"]
  },
  imageTwo: {
    ml: "auto",
    mr: "auto",
    mb: "15%"
  },
};

export default HiringSection;
